
  export default {
    'name':'IntegrationCard',
    props: {
      integration: {
        type: Object,
        required: true
      },
      home: {
        type: Boolean,
        default: false
      }
    }
  }
