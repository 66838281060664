
export default {
  name: 'AppSectionHeader',

  props: {
    noCenter: {
      type: Boolean,
      default: false,
    },

    white: {
      type: Boolean,
      default: false,
    }
  }
}
