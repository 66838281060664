
import AppAvatar from '@/components/AppAvatar.vue'

export default {
  name: 'AdvantageCard',

  components: {
    AppAvatar,
  },

  props: {
    advantage: {
      type: Object,
      required: true
    },
    sideLeft: {
      type: Boolean,
      default: false
    }
  }
}
