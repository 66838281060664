
import AppBtnStyle1 from '@/components/AppBtnStyle1.vue';

export default {
  name: 'AppCarouselHome',
  components: {   
    AppBtnStyle1
  },
  props: {
    height: {
      type: [String, Number],
      default: 520,
    },
    light: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: []
    }
  }
}
