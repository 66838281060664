
import { mdiChevronRight} from '@mdi/js';   

export default {
  name: 'AppBtnStyle1',

  props: {      
    hrefLink: {
      type: String,
      required: true
    },
    customClass: {
      type: String,
      default: ''
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'large' //small, medium, large, very-large
    },
    color: {
      type: String,
      default: 'blue' // 'light-purple', 'purple'
    }          
  },
  data() {
    return {
      mdiChevronRight,
    }
  },
  computed: {
    computedClass() {
      let classes = this.size;
      if (this.customClass) {
        classes += ' ' + this.customClass;
      }
      if (this.outlined) {
        classes += ' outlined';
      }
      else {
        classes += ' solid';
      }
      if (this.color) {
        classes += ' btn-' + this.color;
      }
      return classes;
    }
  }
}
