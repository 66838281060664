
// @ is an alias to /src
import AppAvatar from '@/components/AppAvatar.vue'
import AppBtnNew from '@/components/AppBtnNew.vue'
import AppFullSizeCard from '@/components/AppFullSizeCard.vue'
import AppSectionHeader from '@/components/AppSectionHeader.vue'
import AdvantageCard from '@/components/AdvantageCard.vue'
import IntegrationCard from '@/components/IntegrationCard.vue'
import AppCarouselHome from '@/components/AppCarouselHome.vue'
import { heroLowering, heroColumnPadding, columnPadding } from '@/utils/functions.js'
import { mapGetters} from "vuex";
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'Home',
  components: {
    AppAvatar,
    AppBtnNew,
    AppFullSizeCard,
    AppSectionHeader,
    AdvantageCard,
    IntegrationCard,
    AppCarouselHome
  },
  props:{
    goTo: {
        type: String, 
      },
  },
  created(){

  },

  computed: {
    ...mapGetters([
      'screen_size',
    ]),
    ...mapFields('menu',['menu','drawer']),

    heroLowering() {
      return heroLowering(this.$vuetify.breakpoint.name)
    },

    heroColumnPadding() {
      return heroColumnPadding(this.$vuetify.breakpoint.name)
    },

    columnPadding() {
      return columnPadding(this.$vuetify.breakpoint.name)
    },

    statsCardSpacing() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
        case 'md':
          return 'px-8 py-10 my-14'
        case 'lg':
        case 'xl':
        default:
          return 'pa-16 my-6'
      }
    },

    vuetifyWidth() {
      if (this.isMounted) {
        return this.$vuetify.breakpoint.width
      } else {
        return null
      }
    }
  },

  data () {
      return {
        isMounted: false,
        useCaseHeight: 'auto',
        registered:"0",

        advantages: [
          {
            id: 1,
            title: 'Ahorra tiempo digitalizando tus facturas',
            src: 'advantages/advantage_time.png',
            text: 'Integrado a tu programa contable habitual, Inmatic localiza, sin necesidad de tu ayuda, las facturas duplicadas. Dispone además de un potente gestor documental.',
          },
          {
            id: 2,
            title: 'Instalación y mantenimiento a coste 0',
            src: 'advantages/advantage_maintenance.png',
            text: 'Inmatic es una inversión rentable desde el inicio, ya que no tiene costes asociados a la instalación, implementación o mantenimiento de la solución.',
          },
          {
            id: 3,
            title: 'Facilita la trazabilidad del proceso contable',
            src: 'advantages/advantage_process.png',
            text: 'No se requieren plantillas, capta por sí solo todo tipo de impuestos: IVA, IGIC, suplidos, inversión de sujeto pasivo, intracomunitarias, importaciones, recargo equivalencia, retenciones, entre otros datos. Compatible con contabilidad analítica.',
          },
          {
            id: 4,
            title: 'Olvídate de procesar tus facturas manualmente',
            src: 'advantages/advantage_process.png',
            text: 'Olvídate de los errores manuales. Genera tus asientos contables a golpe de clic aportando a tu equipo mayor autonomía y agilidad. Procesa cualquier documento: facturas, facturas manuscritas, albaranes, tickets... Compatible con cualquier escaner, si tus ojos pueden leerlo, Inmatic también.',
          },
          {
            id: 5,
            title: 'Acelera la productividad de tu equipo y gestoría',
            src: 'advantages/advantage_productivity.png',
            text: 'Reduce la carga de trabajo e invierte tiempo en lo que importa. Procesar facturas y generar los asientos contables nunca había sido tan fácil.',
          },
          {
            id: 6,
            title: 'Absorbe mejor las puntas de trabajo en los cierres',
            src: 'advantages/advantage_calendar.png',
            text: 'Evita el colapso que provocan las facturas duplicadas, incompletas, o que se envían a última hora generando cuellos de botella a finales de trimestre.',
          },
        ],        

        integrations: [
          {
            name: 'Contasol',
            shortName: 'Contasol',
            imgSrc: '/integrations/contasol.webp',
            link: '/compatibilidad/contasol/',
          },
          {
            name: 'Wolters Kluwer A3ECO / A3CON / A3ASESOR', 
            shortName: 'A3ECO',
            imgSrc: '/integrations/a3eco.webp',
            link: '/compatibilidad/wolters-kluwer-a3asesor-eco/',
          },
          {
            name: 'Wolters Kluwer a3innuva',
            shortName: 'a3innuva',
            imgSrc: '/integrations/a3innuva.webp',
            link: '/compatibilidad/wolters-kluwer-a3innuva/',
          },
          {
            name: 'Alfastar',
            imgSrc: '/integrations/alfastar.webp',
            link: '/compatibilidad/alfastar/',
          },
          {
            name: 'Aplifisa Contabilidad y Aplifisa Fiscal',
            shortName: 'Aplifisa',
            imgSrc: '/integrations/aplifisa.webp',
            link: '/compatibilidad/aplifisa/',
          },
          {
            name: 'Contanet',
            imgSrc: '/integrations/contanet.webp',
            link: '/compatibilidad/contanet/',
          },
          {
            name: 'Contaplus',
            imgSrc: '/integrations/contaplus.webp',
            link: '/compatibilidad/sage-contaplus/',
          },          
          {
            name: 'Diamacon / Comeralia',
            imgSrc: '/integrations/diamacon.webp',
            link: '/compatibilidad/diamacon-comeralia/',
          },          
          {
            name: 'DiezSoftware',
            imgSrc: '/integrations/diez-software.webp',
            link: '/compatibilidad/cegid-diez-diezcon-erpdiez/',
          },          
          {
            name: 'Gesfincas',
            imgSrc: '/integrations/gesfincas.webp',
            link: '/compatibilidad/gesfincas/',
          },
          {
            name: 'Geyce',
            shortName: 'Geyce',
            imgSrc: '/integrations/geyce.webp',
            link: '/compatibilidad/geyce/',
          },
          {
            name: 'Glasof',
            imgSrc: '/integrations/glasof.webp',
            link: '/compatibilidad/glasof/',
          },          
          {
            name: 'Golden Soft',
            imgSrc: '/integrations/goldensoft.webp',
            link: '/compatibilidad/golden-soft/',
          },
          {
            name: 'Holded',
            imgSrc: '/integrations/holded.webp',
            link: '/compatibilidad/holded/',
          },
          {
            name: 'Monitor Informática',
            shortName: 'Monitor Inform',
            imgSrc: '/integrations/monitor-informatica.webp',
            link: '/compatibilidad/monitor-informatica-miconta/',
          },
          {
            name: 'NCS Software',
            shortName: 'NCS',
            imgSrc: '/integrations/ncs.webp',
            link: '/compatibilidad/ncs/',
          },
          {
            name: 'NetFincas',
            shortName: 'NetFincas',
            imgSrc: '/integrations/netfincas.webp',
            link: '/compatibilidad/netfincas/',
          },
          {
            name: 'Odoo',
            imgSrc: '/integrations/odoo.webp',
            link: '/compatibilidad/odoo/',
          },
          {
            name: 'Sage Despachos, Sage 50 y Sage 200',
            shortName: 'Sage',
            imgSrc: '/integrations/sage.webp',
            link: '/compatibilidad/sage-50-sage-200-sage-despachos/',
          },
        ],

        stats: [
          {
            id: 1,
            topText: 'Reduce el',
            midText: '70%',
            bottomText: 'del <strong>tiempo</strong> destinado a <br><strong>contabilizar</strong> tus <strong>facturas</strong>.'
          },
          {
            id: 2,
            topText: 'Más de',
            midText: '+800',
            bottomText: 'clientes <strong>satisfechos</strong> que ya <br><strong>automatizan</strong> su contabilidad.',
          },
          {
            id: 3,
            topText: 'Más de',
            midText: '+200.000',
            bottomText: '<strong>horas</strong> de trabajo <strong>ahorradas</strong>.<br> Elimina los picos de trabajo.',
          },
        ],

        useCases: [
          {
            imgSrc: '/avatar-asesoria.svg',
            title: 'ASESORÍAS CONTABLES Y FISCALES',
            text: 'Especialmente diseñado para gestorías. Aprovecha con Inmatic las múltiples herramientas que te pueden ayudar a multiplicar la productividad de tu equipo contable y fiscal, y la comunicación con tus clientes.',
            link: '/segmentos/asesorias-contables-y-fiscales/'
          },
          {
            imgSrc: '/avatar-contabilidad.svg',
            title: 'DEPARTAMENTOS CONTABLES',
            text: 'Los contables trabajan intensamente en la operativa diaria, incluyendo revisiones contables para un ejercicio libre de errores. Con Inmatic su trabajo ahora es más fácil.',
            link: '/segmentos/departamentos-contables/'
          },
          {
            imgSrc: '/avatar-contabilidad.svg',
            title: 'ADMINISTRADORES DE FINCAS',
            text: 'Los administradores de fincas lidian con procesos diarios a nivel contable, gestión de pagos y obligaciones fiscales, brindando asesoramiento financiero que ahora pueden automatizar.',
            link: '/segmentos/administradores-de-fincas/'
          }
        ],
        carouselNews: [
          {
            imgSrc: '/blog/carousel/carousel-bank.webp',
            title: 'El nuevo módulo de <span class="featured">conciliación bancaria</span> de Inmatic te ayuda a optimizar tu contabilidad',
            text: 'Conoce el nuevo módulo de conciliación bancaria de Inmatic, basado en Inteligencia Artificial, para simplificar y automatizar la contabilización de movimientos bancarios de las empresas.',
            link: '/blog/modulo-conciliacion-automatica-bancos-inmatic/',
          },
          {
            imgSrc: '/blog/carousel/carousel-ia.webp',
            title: '<span class="featured">Inteligencia Artificial en la Contabilidad</span>: Automatización y Eficiencia',
            text: 'Descubre cómo la inteligencia artificial está transformando la contabilidad, automatizando procesos, aumentando la eficiencia y ofreciendo un control financiero de sin precedentes.',
            link: '/blog/inteligencia-artificial-contabilidad/',
          },
          {
            imgSrc: '/blog/carousel/carousel-ocr.webp',
            title: 'Cómo elegir un <span class="featured">OCR contable</span> para digitalizar facturas',
            text: 'Sabemos que puede ser difícil encontrar el OCR contable perfecto entre las diferentes alternativas disponibles. En este artículo, te ayudaremos a identificar los aspectos clave que debes tener en cuenta para tomar la mejor decisión para tu empresa.',
            link: '/blog/guia-del-comprador-ocr/',
          },
          {
            imgSrc: '/blog/carousel/carousel-gestoria.webp',
            title: 'Cómo <span class="featured">escalar la productividad</span> de tu gestoría y aumentar tu cartera de clientes',
            text: 'Aprende en este artículo cómo optimizar la productividad de tu gestoría o asesoría para dedicar más tiempo a expandir tu cartera de clientes. Encuentra aquí las claves para llevar tu negocio al siguiente nivel. ',
            link: 'blog/escalar-productividad-y-aumentar-clientes-en-tu-gestoria/',
          }
        ],
        tags_lead: ['home_inmatic'],
        showVideo: false,
        textVideoBtn: 'VER VÍDEO'
      }
    },

  head: {
    meta: [
      {hid: 'og-type', property: 'og:type', content: 'website'},
      {hid: 'og-title', property: 'og:title', content: 'Inmatic - Contabilización automática de facturas'},
      {hid: 'og-desc', property: 'og:description', content: 'Logra la automatización contable con Inmatic y reduce el tiempo de contabilización de tus facturas a la mitad. Solución basada en AI.¡Pruébalo ahora!' },
      {hid: 'og-image', property: 'og:image', content: 'https://inmatic.ai/og-inmatic.jpg'},
      {hid: 'og-url', property: 'og:url', content: 'https://inmatic.ai'},
      {hid: 't-type', name: 'twitter:card', content: 'summary_large_image'},      
    ],
    link: [
      {hid: 'canonical', rel: 'canonical', href: 'https://inmatic.ai'},
    ]
  },
  mounted () {
    this.isMounted = true
    this.resizeUseCasesCards()   
  },

  watch: {
    vuetifyWidth() {
      if (this.isMounted) {
        this.resizeUseCasesCards()
      }
    }
  },

  methods: {
    playVideo(){
      if(!this.showVideo) {
        this.$refs.video.play();
        this.showVideo = true;
        this.textVideoBtn = "PARAR VÍDEO";
      }
      else {
        this.$refs.video.pause();
        this.$refs.video.currentTime = 0;
        this.showVideo = false;
        this.textVideoBtn = "VER VÍDEO";
      }
    },

    resizeUseCasesCards() {
      this.useCaseHeight = 'auto'

      const resize = () => {
        const casesHeight = this.$refs.useCases.map(useCase => useCase.offsetHeight)
        const highestHeightValue = casesHeight.reduce((elm, acc) => elm > acc ? elm : acc)
        this.useCaseHeight = highestHeightValue
      }

      setTimeout(resize, 200);
    
    }
  }
}
