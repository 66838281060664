
export default {
  name: 'AppAvatar',

  props: {
    color: {
      type: String,
      default: 'primary',
    },

    size: {
      type: [String, Number],
      default: 60,
    },

    src: {
      type: String,
      required: true,
    },

    fullSizeIcon: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    imageSize () {
      if (this.fullSizeIcon) {
        return this.size
      }
      return parseInt(this.size) - 15
    },

    containerStyle() {
      return {
        height: `${this.size}px`,
        width: `${this.size}px`,
        borderRadius: '50%'
      }
    },

    containerClass() {
      return this.color
    }
  }
}
