/*
  Funciones estilos computados compartidas entre componentes
*/

export function heroLowering(breakpoint) {
  switch (breakpoint) {
    case 'xs':
    case 'sm':
    case 'md':
      return 'pt-16'
    case 'lg':
    case 'xl':
    default:
      return 'pt-5'
  }
}

export function heroColumnPadding(breakpoint) {
  switch (breakpoint) {
    case 'xs':
    case 'sm':
    case 'md':
      return 'px-10'
    case 'lg':
    case 'xl':
    default:
      return 'pl-16'
  }
}

export function columnPadding(breakpoint) {
  switch (breakpoint) {
    case 'xs':
    case 'sm':
    case 'md':
      return 'px-10'
    case 'lg':
    case 'xl':
    default:
      return 'padded-column'
  }
}

